import forEach from 'lodash/forEach'

const switchDepo = (e) => {
  window.location = e.target.getAttribute('data-url')
}

const deposSwitch = () => {
  const depoList = document.getElementsByClassName('depos-list')[0]

  if (depoList) {
    forEach(depoList.childNodes, (node) => {
      node.addEventListener('click', switchDepo)
    })
  }
}

export default deposSwitch