import 'material-design-lite/dist/material.min.js'
import 'material-design-lite/dist/material.min.css'

import 'material-design-icons/iconfont/material-icons.css'
import 'leaflet/dist/leaflet.css'
import 'leaflet-draw/dist/leaflet.draw.css'
import 'dragula/dist/dragula.css'
import '../../packs/leaflet-numbered-markers/dist/leaflet.awesome-markers.css'
import '../../packs/leaflet-numbered-markers/dist/leaflet.awesome-markers.js'

window.addEventListener('unhandledrejection', (e) => {
  console.error(`Unhandled rejection (promise: ${JSON.stringify(e.promise)}, reason: ${e.promise}`);
});

import setupAxios from '../setupAxios'
setupAxios()
window.addEventListener('DOMContentLoaded', setupAxios)

import tenant from '../tenant'
window.tenant = tenant()

import userRole from '../userRole'
window.userRole = userRole()

import userDepoIds from '../userDepoIds'
window.userDepoIds = userDepoIds()

import currency from '../currency'
window.currency = currency()

import depoId from '../depoId'
window.depoId = depoId()

import * as Routes from '../../routes.js.erb'
window.Routes = Routes

import Sentry from '../sentry'
window.Sentry = Sentry

import ready from '../ready'

import deposSwitch from '../deposSwitch'
ready(deposSwitch)

window.isHjemis = window.tenant === 'hjem-is'
window.isIsbilen = window.tenant === 'den-norske-isbilen'

window.isAdmin     = userRole() == 'admin'
window.isDepoAdmin = userRole() == 'depo_admin'

window.timeFormat     = 'HH:mm'
window.dateFormat     = 'DD-MM-YYYY'
window.dateTimeFormat = 'DD-MM-YYYY HH:mm'
