import * as Sentry from '@sentry/browser'

const ENVIRONMENTS = ['demo', 'production']

const env = document.querySelector('meta[name=env]').content

Sentry.init({
  dsn: 'https://eb889b3b1b4c4d71a750f1b8625d2e5f@o185386.ingest.sentry.io/5279543',
  environment: env,
  enabled: ENVIRONMENTS.includes(env)
})

export default Sentry
